<template>
  <div class="card-slider-wrapper">
    <div class="card-slider-inner">
      <div class="swiper card-slider">
        <div class="swiper-wrapper" v-if="width > 768">
          <BannerSlide image="/images/bannerslider/banner9.webp" />
          <BannerSlide image="/images/bannerslider/banner11.webp" />
          <BannerSlide image="/images/bannerslider/banner6.webp" />
          <BannerSlide tab="weeklyRaffle" image="/images/bannerslider/rafflepuntmain.png" class="cursor-pointer"/>
          <BannerSlide tab="bank" image="/images/bannerslider/black-friday-punt.png" class="cursor-pointer"/>
           
        </div>
        <div class="swiper-wrapper mobileslider" v-else>
          <BannerSlide image="/images/bannerslider/banner9-mobile.webp" />
          <BannerSlide image="/images/bannerslider/banner11-mobile.webp" />
          <BannerSlide image="/images/bannerslider/banner6-mobile.webp" />
          <BannerSlide tab="weeklyRaffle" class="cursor-pointer" image="/images/bannerslider/rafflepuntmobile.png"/>
          <BannerSlide tab="bank" image="/images/bannerslider/black-friday-mobile.png" class="cursor-pointer"/>
        </div>
      </div>
      <div class="slider-navigation">
        <a class="prev">
          <svg
            width="19"
            height="8"
            viewBox="0 0 19 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 3.5C18.2761 3.5 18.5 3.72386 18.5 4C18.5 4.27614 18.2761 4.5 18 4.5V3.5ZM0.646446 4.35355C0.451185 4.15829 0.451185 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM18 4.5H1V3.5H18V4.5Z"
              fill="white" />
          </svg>
        </a>
        <div class="slider-pagination"></div>
        <a class="next">
          <svg
            width="19"
            height="8"
            viewBox="0 0 19 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM18.3536 4.35355C18.5488 4.15829 18.5488 3.84171 18.3536 3.64645L15.1716 0.464466C14.9763 0.269204 14.6597 0.269204 14.4645 0.464466C14.2692 0.659728 14.2692 0.976311 14.4645 1.17157L17.2929 4L14.4645 6.82843C14.2692 7.02369 14.2692 7.34027 14.4645 7.53553C14.6597 7.7308 14.9763 7.7308 15.1716 7.53553L18.3536 4.35355ZM1 4.5H18V3.5H1V4.5Z"
              fill="white" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Autoplay, EffectCards, Navigation, Pagination } from 'swiper';
import BannerSlide from './BannerSlide.vue';
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/css/effect-cards';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
export default {
  name: 'BannerSlider',
  mounted() {
    setTimeout(() => {
      new Swiper('.card-slider', {
        modules: [Autoplay, EffectCards, Navigation, Pagination],
        effect: 'cards',
        loop: true,
        loopAdditionalSlides:4,
        cardsEffect: {
          perSlideOffset: 20,
          perSlideRotate: 3,
        },
        navigation: {
          nextEl: '.slider-navigation .next',
          prevEl: '.slider-navigation .prev',
        },
        pagination: {
          el: '.slider-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 3000,
        },
      });
    }, 500);
  },
  components: {
    BannerSlide,
  },
  data() {
    return {
      width: 0,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
  },
};
</script>

<style>
.sidebar_wrapper::before {
  content: url('/images/bank/sidebarlight.png');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

@media (max-width: 767px) {
  .swiper .card-slider {
    width: 80% !important;
  }
}
</style>
