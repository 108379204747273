<template>
    <Popover v-slot="{ open }" class="relative max-h-full">
      <PopoverButton
        :class="open ? '' : 'text-opacity-90'"
        class="bg-[rgb(37_37_70_/_60%)] px-2 py-2 md:px-4 rounded-lg font-semibold flex flex-inline items-center transition ease-in-out delay-0 duration-150">
        <div class="flex flex-row space-x-2 items-center text-slate-300">
          <span class="text-sm pl-1 flex flex-row">
            {{ sortOrder(sort) }}
          </span>
          <img alt="icon" src="../../assets/svg/chevron.svg" class="mx-2" />
        </div>
      </PopoverButton>
  
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0">
        <PopoverPanel
          class="absolute md:px-4 mt-3 md:transform md:-translate-x-1/2 md:left-1/2 z-50 min-w-[175px] max-w-[175px] -right-8 px-4 mt-3">
          <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="overflow-y-auto relative bg-slate-100 rounded-lg mx-2">
              <popover-button
                v-on:click="changeSortOrder('asc')"
                class="w-full px-2 md:px-4 py-1 cursor-pointer transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                <div
                  :class="[isSelected('Oldest') ? 'text-onprimary' : 'text-black/70 ']"
                  class="flex flex-row space-x-2 w-full justify-start text-[0.9rem]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                  </svg>
                  <p>Oldest</p>
                </div>
              </popover-button>
              <popover-button
                v-on:click="changeSortOrder('desc')"
                class="w-full px-2 md:px-4 py-1 cursor-pointer transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                <div
                  :class="[isSelected('Newest') ? 'text-onprimary' : 'text-black/70 ']"
                  class="flex flex-row text-[0.9rem] space-x-2 w-full justify-start items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                  </svg>
                  <p>Newest</p>
                </div>
              </popover-button>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </template>
  
  <script>
  import {
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    Switch,
    SwitchGroup,
    SwitchLabel,
  } from '@headlessui/vue';
  import { ChevronDownIcon } from '@heroicons/vue/solid';
  import SelectFiat from '@/components/Bank/SelectFiat.vue';
  
  export default {
    name: 'Providers',
    components: {
      Popover,
      PopoverButton,
      PopoverPanel,
      ChevronDownIcon,
      Switch,
      SwitchGroup,
      SwitchLabel,
      PopoverOverlay,
      SelectFiat,
    },
    setup() {
      return {};
    },
    props: {
      sort: {
        default: 'asc',
      },
      changeSortOrder: {
        default: () => {},
      },
    },
    methods: {
      isSelected(order) {
        return order === this.sort;
      },
      sortOrder(order) {
        if (order === 'desc') return 'Newest';
        if (order === 'asc') return 'Oldest';
        return 'Created At';
      },
    },
  };
  </script>
  