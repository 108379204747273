<template>
  <div class="px-1 md:px-4 home_slider">
    <div class="swiper max-w-[100%] rounded-lg my-2 md:my-4" :class="'swiper_'+`${category?.id ?? 'main'}`">
      <div v-if="category?.data?.images.length > 0" class="swiper-wrapper">
        <div
            v-for="slide in category?.data?.images"
            class="swiper-slide"
        >
          <div class="swiper-carousel-animate-opacity">
            <img v-if="slide.type==='page'" v-on:click="handlePageClick(slide)" class="bg-image" :class="{'cursor-pointer': slide.url}" :src="getSlideImageUrl(slide.image)" alt="" />
            <img v-else-if="slide.type==='tab'" v-on:click="handlePageClick(slide)" class="bg-image" :class="{'cursor-pointer': slide.url}" :src="getSlideImageUrl(slide.image)" alt="" />
            <img v-else class="bg-image" :src="getSlideImageUrl(slide.image)" alt="" />
          </div>
        </div>
      </div>
      <div v-else-if="!category && isMainBanner" class="swiper-wrapper">
        <div class="swiper-slide">
          <!-- elements with  "swiper-carousel-animate-opacity" class will have animated opacity -->
          <div class="swiper-carousel-animate-opacity">
            <img class="bg-image" src="../../../assets/banners/BannerCoins.webp" alt="" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper-carousel-animate-opacity">
            <img class="bg-image" src="../../../assets/banners/BannerBeach.webp" alt="" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper-carousel-animate-opacity">
            <img class="bg-image" src="../../../assets/banners/BannerTable.webp" alt="" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper-carousel-animate-opacity">
            <img v-if="isLoggedIn" v-on:click="$router.push({ path: $route.path, query: { tab: 'weeklyRaffle' } })" class="bg-image cursor-pointer" src="../../../assets/banners/raffflev6.png" alt="" />
            <img v-else v-on:click="handlePageClick()" class="bg-image cursor-pointer" src="../../../assets/banners/raffflev6.png" alt="" />
          </div>
        </div>

      </div>
    </div>
  </div>
  <button v-tooltip="'Chat Rules'" v-on:click="showChatRules" class="hidden"></button>
</template>

<script type="module">
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { mapGetters } from 'vuex';
/**
 * import Carousel effect module
 */
import EffectCarousel from './effect-carousel.esm';
/**
 * import Swiper styles
 */
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'swiper/scss/virtual';
/**
 * import Carousel effect styles
 */
import './effect-carousel.scss';
/**
 * Custom local styles
 */

import './main.scss';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'HomeSlider',
  props: {
    category: {
      type: Object,
      required: true,
    },
    isMainBanner: {
      type: Boolean,
      required: false,
    }
  },
  mounted() {
    const data = this.category?.data;
    const slidesPerRow = data?.slides_per_row > data?.images?.length ? data?.images?.length : data?.slides_per_row;
    const swiper = new Swiper(`.swiper_${this.category?.id ?? 'main'}`, {
      // pass EffectCarousel module to modules
      modules: [Autoplay, Navigation, Pagination],
      // specify "carousels" effect
      effect: 'carousel',
      grabCursor: false,
      loop: slidesPerRow ? data?.images.length > slidesPerRow : true,
      loopedSlides: 0,
      slidesPerView: (isMobile ? 1 : slidesPerRow ?? 3),
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
      autoplay: {
        delay: 3000,
      },
      on: {
        click: (swiper, event) => {
          const clickedSlide = swiper.clickedSlide;
          if (clickedSlide) {
            const realIndex = swiper.clickedSlide.getAttribute('data-swiper-slide-index');
            const slideData = this.category.data.images[realIndex];

            if (slideData && slideData?.type === 'page' || slideData?.type === 'tab') {
              this.handlePageClick(slideData);
            }
          }
        }
      }
    });

    swiper.autoplay.start();
  },
  methods: {
    getSlideImageUrl(path) {
      return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/${path}`;
    },
    handlePageClick(slide = null) {
      if(!this.isLoggedIn) {
        this.$router.push({ path: this.$route.path, query: { tab: 'register' } });
      }
      else {
        if(slide.type === 'tab') {
          this.$router.push({ path: this.$route.path, query: { tab: slide.url } });
        }
        else if(slide.type === 'page') {
          if (slide.open_in_new_tab === true) {
            if(this.isValidUrl(slide.url)) {
              window.open(slide.url, '_blank');
            }
            else{
              window.open(this.$router.resolve({ path: slide.url }).href, '_blank');
            }
          } else {
            if(this.isValidUrl(slide.url)) {
              window.open(slide.url, '_self');
            } else {
              this.$router.push({ path: slide.url });
            }
          }
        }
      }
    },
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters([ 'isLoggedIn']),
  }
};
</script>