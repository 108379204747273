const GamePlayAmountTitle = "Game play amount"
export const all = [
    {
        "id" : 1,
        "title" : "Copper",
        "style" : "copper",
        "image" : "copper",
        "GamePlayAmount" : "10K",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 2,
        "title" : "Bronze",
        "style" : "bronze",
        "image" : "bronze",
        "GamePlayAmount" : "50K",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 3,
        "title" : "Silver",
        "style" : "silver",
        "image" : "silver",
        "GamePlayAmount" : "100K",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 4,
        "title" : "Gold",
        "style" : "gold",
        "image" : "gold",
        "GamePlayAmount" : "250K",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]     
    }
];
export const platinum = [
    {
        "id" : 1,
        "title" : "Platinum I",
        "style" : "platinum",
        "image" : "platinum",
        "GamePlayAmount" : "500K",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 2,
        "title" : "Platinum II",
        "style" : "platinum",
        "image" : "platinum",
        "GamePlayAmount" : "1M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 3,
        "title" : "Platinum III",
        "style" : "platinum",
        "image" : "platinum",
        "GamePlayAmount" : "2.5M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 4,
        "title" : "Platinum IV",
        "style" : "platinum",
        "image" : "platinum",
        "GamePlayAmount" : "5M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 5,
        "title" : "Platinum V",
        "style" : "platinum",
        "image" : "platinum",
        "GamePlayAmount" : "10M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]      
    }    
]
export const diamond = [
    {
        "id" : 1,
        "title" : "DIAMOND I",
        "style" : "diamond",
        "image" : "diamond",
        "GamePlayAmount" : "25M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 2,
        "title" : "DIAMOND II",
        "style" : "diamond",
        "image" : "diamond",
        "GamePlayAmount" : "50M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    },
    {
        "id" : 3,
        "title" : "DIAMOND III",
        "style" : "diamond",
        "image" : "diamond",
        "GamePlayAmount" : "100M",
        "GamePlayAmountTitle" : GamePlayAmountTitle,
        "lists" : ["Rakeback","Level Up Bonus","Weekly Bonus"]       
    }
]