<template>
  <div class="md:px-6 px-2 py-2 max-w-[100%]">
    <div class="px-2 md:py-4 flex flex-row justify-between items-center max-[575px]:px-0">
      <router-link
        to="?tab=bank"
        class="flex items-center justify-start gap-2 py-0 md:py-4">
        <img class="h-[26px] w-[30px]" src="../../assets/sidebar/wallet.png" alt="" />
        <p class="font-semibold min-w-max text-md md:text-xl">Quick Buy</p>
      </router-link>
      <div class="flex">
        <button
          v-on:click="scroll(false)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border border-slate-600 rounded-l-xl hover:opacity-60 cursor-pointer">
          <!--          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd" />
          </svg>-->
          <img
            src="../../assets/3d/right-arrow.png"
            class="h-5 sm:h-6 md:h-8 -rotate-180" />
        </button>
        <button
          v-on:click="scroll(true)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border border-slate-600 rounded-r-xl hover:opacity-60 cursor-pointer">
          <img src="../../assets/3d/right-arrow.png" class="h-5 sm:h-6 md:h-8" />
        </button>
      </div>
    </div> 
    <div ref="bundle" class="no-scrollbar bundle home-casino-slider overflow-x-scroll py-2">
      <div
          v-for="(bundle, i) in bundles"
          class="   bundle-tile   flex flex-col rounded-xl bg-[rgba(215,215,215,0.08)]"
          :class="[                  isDisabled(bundle.price) ? 'opacity-50' : '',
                  hasBlackFridaySale(bundle) ? 'border-4 border-yellow/80' : ''  ]"

          :key="i"  >
          <div
            class="flex flex-row bundle-card relative my-2 ml-2 px-1.5 py-[3px] rounded-full items-center text-sm w-fit max-[480px]:ml-4 max-[480px]:text-[12px]">
            <img
              alt="USD Coin"
              class="w-[13.33px] h-[13.33px]"
              src="../../assets/coins/cash.png" />
            <span class="ml-1">{{ bundle.price }} free SC</span>
          </div>
          <div class="flex flex-col items-center justify-center px-3 pb-2.5 relative">
            <img class="h-20 max-[575px]:h-16 mb-2.5" :src="bundle.img" alt="coins" />
            <img
              alt="shadow Image"
              class="absolute top-[-41px] w-full h-full -translate-x-2/4 left-2/4 pointer-events-none"
              src="/images/bank/bundlecardshadow.png" />
            <span class="w-full">{{ (bundle.price * 10000).toLocaleString() }}</span>
            <span class="text-xs w-full text-primary-text">Gold Coins</span>
            <button
              v-on:click="onBundleClicked(bundle.price)"
              :disabled="isOnBreak || isDisabled(bundle.price)"
              class="btn-bet bg-onprimary hover:bg-onprimary/90 rounded-[10px] hover:opacity-75 px-4 py-2.5 mt-3 w-full max-[575px]:px-3 max-[575px]:mt-2 max-[575px]:text-sm max-[575px]:rounded-xl max-[350px]:w-1/2">
              <div v-if="hasBlackFridaySale(bundle)" >
                <span class="line-through-large mr-2">${{ bundle.price }}</span>
                <span class="text-lg">${{ getBundlePrice(bundle) }}</span>
                
              </div>
              <div v-else>
                ${{ getBundlePrice(bundle) }}
              </div>
            </button>
          </div>
        </div>
  </div></div>
</template>

<script>
import { computed } from 'vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'QuickBuy',
  props: {
    
    isOnBreak: {
      default: false,
    }
  },
  data() {
    return { 
      scrollAmount: 0, currentIndex: 0,
     };
    
  },
  setup() {
    
    const balS = balanceStore();
    const bundles = balS.getAvailableBundle
      const blackFridayBundles = computed(() => balS.getBlackFridayBundles);
    return { bundles,balS,blackFridayBundles };
  },
  computed: {
    ...mapGetters(['me']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    onBundleClicked(price) {
      if (this.isOnBreak) {
        this.$toast.error('Your "Break-In-Play" is active till: '+this.me.break_till_date);
        return;
      }
       
        this.$router.push({
          path: this.$route.path,
          query:  { tab:'bank',bundle:price},
        }); 
    },
    isDisabled(amount)
      {
        // console.log((this.me.purchase_limit_bundles)?this.me.purchase_limit_bundles.includes(amount):false);
        return (this.me.purchase_limit_bundles)?this.me.purchase_limit_bundles.includes(amount):false;
      },
    scroll(isRight = true) {
      this.setFade(isRight);
      const menu = this.$refs.bundle;

      const slide_width = menu.getElementsByClassName('bundle-tile')[0].clientWidth;

      menu.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      });
    },
    hasBlackFridaySale(bundle) {
      return   this.me.is_black_friday_sale_available && !!this.blackFridayBundles[bundle.price];
    },
    getBundlePrice(bundle)
    {
      const blackfridaySaleactive = this.blackFridayBundles[bundle.price];
 
      if( this.me.is_black_friday_sale_available && blackfridaySaleactive )
        return this.blackFridayBundles[bundle.price].discounted_price;
      else
        return bundle.price;
    },
    setFade(isRight = true) {
      const menu = this.$refs.bundle;

      if (menu) {
        const width = menu.scrollWidth - menu.clientWidth;
        const total_part = Math.ceil(menu.scrollWidth / menu.clientWidth);
        const all_slide = menu.getElementsByClassName('bundle-tile');
        for (const slide of all_slide) {
          slide.classList.remove('fade');
        }
        const slide_width = menu.getElementsByClassName('bundle-tile')[0].clientWidth;
        const visible_item = Math.ceil(menu.clientWidth / slide_width);

        this.currentIndex = isRight
          ? this.currentIndex + visible_item
          : this.currentIndex - visible_item;

        var next_visible_index = this.currentIndex;

        if (this.currentIndex <= visible_item) {
          next_visible_index = visible_item;
        }

        const all_faded = menu.querySelectorAll(
          '.bundle-tile:nth-child(n+' + next_visible_index + ')',
        );

        for (const fade of all_faded) {
          fade.classList.add('fade');
        }

        const next_visible_item =
          menu.getElementsByClassName('bundle-tile')[next_visible_index - 1];

        if (next_visible_item) {
          this.scrollAmount = next_visible_item.offsetLeft - menu.offsetLeft;
        }

        if (!isRight && this.currentIndex <= 0) {
          this.scrollAmount = 0;
          this.currentIndex = 0;
        }
        if (this.currentIndex >= total_part) {
          this.currentIndex = total_part;
        }
      }
    },
  },
};
</script>

<style scoped>

  
.bundle-card {
  background-color:rgba(0, 255, 186, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}
.coin_purchase .coin-img-wrapper {
  text-align: center;
  position: relative;
}
.coin_purchase .coin-img-wrapper img {
  display: block;
  margin: auto;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
}
.coin_purchase .coin_purchase_left_part {
  background-image: url('../../assets/coins/coin_bg.png');
  background-position: bottom left;
  background-size: 100%;
  background-repeat: no-repeat;
}
.coin_purchase .coin-img-wrapper::before {
  content: url('../../assets/coins/bg_shadow.png');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100px;
  z-index: 0;
}
.coin_purchase .coin_purchase_left_content {
  position: relative;
  z-index: 5;
}
.coin_purchase .pay_method_btn .pay_method-span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a3a3a;
  border-radius: 50%;
  border: 1px solid #415858;
}
.coin_purchase .pay_method_btn:hover .pay_method-span {
  border: 1px solid #00ffba;
}
.coin_purchase .pay_method_btn .pay_method-span span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.coin_purchase .pay_method_btn {
  border: 1px solid transparent;
  background: #112021;
}
.coin_purchase .pay_method_btn:hover {
  background:
    linear-gradient(#112021, #112021) padding-box,
    linear-gradient(213deg, #00ffba 50%, #0cce42 90%, #112021 114%) border-box;
}
.coin_purchase .pay_method_btn:hover .pay_method-span span {
  @apply bg-onprimary;
}
.pay_method_btn .pay_method_types img {
  max-width: 100%;
  margin: 0;
}
.pay_method_btn .pay_method_types .img-wrapper {
  padding: 14px 9px;
  background: #212f2f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.pay_method_btn:hover .pay_method_types .img-wrapper {
  opacity: 1;
}
 .line-through-large {
  text-decoration: line-through;
  text-decoration-thickness: 3px;
}
@media screen and (max-width:768px){
  .coin_purchase_backbtn{
    margin: 10px ;
    margin: auto
  }
}
@media screen and (max-width:500px){
  .coin_purchase_backbtn{
    margin: 20px auto
  }
}
 

.bundle.home-casino-slider
{
  grid-auto-columns: calc(22% - 10px);
  gap: 11px;
}

@media (max-width: 992px) {
  .bundle.home-casino-slider {
      grid-auto-columns: calc(33.33% - 10px);
  }
}

@media (max-width: 768px) {
  .bundle.home-casino-slider {
      grid-auto-columns: calc(50% - 10px);
  }
}

 
</style>
