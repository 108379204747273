<template>
  <punt-modal show-close="true" size="max-w-[450px] min-h-[450px] p-4 my-3" bgColor="bg-gray-800">
    <template #title>
      <div class="flex flex-col">
        <span class="font-bold text-lg">5,000 SC AND 50 Million GC Raffle</span>
      </div>
    </template>
    <template #body>
      <div v-if="selectedOutput === 'general'">
        <div class="flex justify-center items-centerw">
          <img class="h-64 w-64" src="../../assets/cartoonWheel.png" style="vertical-align: middle" />
        </div>
        <div class="grid grid-cols-4 gap-1 items-center text-white text-center text-sm max-w-fit my-4">
          <div class="bg-gray-950">
            <div>
              {{ this.timeUntilRaffle(this.timeUntilFriday).days }}
            </div>
            <div class="text-slate-400 mx-2 max-w-12">Days</div>
          </div>
          <div class="bg-gray-950">
            <div>
              {{ this.timeUntilRaffle(this.timeUntilFriday).hours }}
            </div>
            <div class="text-slate-400 mx-2 max-w-12">Hours</div>
          </div>
          <div class="bg-gray-950">
            <div>
              {{ this.timeUntilRaffle(this.timeUntilFriday).minutes }}
            </div>
            <div class="text-slate-400 mx-2 max-w-12">Min</div>
          </div>
          <div class="bg-gray-950">
            <div>
              {{ this.timeUntilRaffle(this.timeUntilFriday).seconds }}
            </div>
            <div class="text-slate-400 mx-2 max-w-12">Sec</div>
          </div>
        </div>
        <div class="my-6 text-white/90">
          <div class="grid grid-cols-2">
            <div>Next Ticket</div>
            <div class="h-5 flex justify-self-end mx-2">
              <img class="mx-1" src="../../assets/coins/cash.png" style="vertical-align: middle" />
              {{ this.loading ? 'Loading' : Number(sweepCoinsSpent % 1000).toFixed(2) }} /
              <img class="mx-1" src="../../assets/coins/cash.png" style="vertical-align: middle" />1,000
            </div>
          </div>
          <div class="my-2"></div>
          <div class="w-full rounded-full progress-bar">
            <div class="srounded-full striped-bar"
              :style="'width:' + (this.loading ? 0 : sweepCoinsSpent % 1000 / 1000 * 100) + '%'"></div>
          </div>
          <div>Your number of entries: {{ this.loading ? 'Loading' : currentTickets?.length }}</div>
        </div>
        <div class="text-white/90 mb-4">
          Play to earn tickets into a giveaway where anybody can win! Just one ticket could see you sharing in GC + free
          SC every single week. With 1,000 SC played equating to one ticket, earn as many tickets as possible to give
          yourself the best chance of winning big! Winners drawn on live stream every Saturday from 3 to 5 PM PST at youtube.com/@FishySlots
        </div>
        <div class="grid grid-cols-2 grid-flow-col text-center">
          <button v-on:click="changeSelectedOutput('moreInfo')"
            class="h-[100%] md:h-12 items-center content-center justify-center mx-2 rounded-lg bg-primary font-semibold text-white/90 ">Read
            more</button>
          <button v-on:click="changeSelectedOutput('tickets')"
            class="h-[100%] md:h-12 items-center content-center justify-center mx-2 rounded-lg bg-accent font-semibold text-white/90 ">View
            your tickets</button>
        </div>
      </div>
      <div v-if="selectedOutput === 'moreInfo'">
        <div class="text-white/90 my-4">The total prize pool is 5,000 Sweeps Coins and 50M Gold Coins and you can win
          more than once! Earn tickets for every 1,000 Sweeps Coins you play on qualifying games. The raffle takes
          place between Friday at 12AM PST to the following Friday at 12AM PST, the countdown will show you how long you
          have left to enter and your progress to your next ticket. Winners will be announced on Saturday evening during
          a livestream, tune in to see if you've won!</div>
        <button v-on:click="changeSelectedOutput('general')"
          class="w-[50%] h-[100%] md:h-12 items-center content-center justify-center mx-2 rounded-lg bg-primary font-semibold text-white/90">Back</button>
      </div>
      <div v-if="selectedOutput === 'tickets'">
        <div class="text-white/90 my-4" v-if="previousRaffle && !previousRaffle.completed">Pending Raffle Tickets
          Drawable Today</div>
        <div class="text-white/90 my-4" v-else>Upcoming Raffle Tickets</div>
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full sm:px-6">
            <table class="table-auto md:table-fixed w-full">
              <thead class="">
                <tr>
                  <th class="md:py-3 md:px-6 py-1 px-3 text-sm text-left text-gray-400 w-[100px]" scope="col">
                    Ticket Number
                  </th>
                  <th class="md:py-3 md:px-6 py-1 px-3 text-sm text-center text-gray-400" scope="col">
                    Earned
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ticket, i) in previousRaffle && !previousRaffle.completed ? previousTickets : currentTickets"
                  v-bind:key="i" class="odd:bg-secondary-light odd:opacity-90 rounded-lg">
                  <td class="md:py-4 md:px-6 py-2 px-3 text-sm font-medium text-slate-300">
                    <label class="md:flex flex-row items-center justify-start text-start w-full">
                      {{ ticket.number }}
                    </label>
                  </td>
                  <td class="md:py-4 md:px-6 py-2 px-3 text-sm font-medium text-slate-300">
                    <label class="flex flex-row items-center justify-center text-center w-full">
                      {{ new Date(ticket.created_at).toDateString() }}
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button v-on:click="changeSelectedOutput('general')"
          class="w-[50%] h-[100%] md:h-12 items-center content-center justify-center mx-2 rounded-lg bg-primary font-semibold text-white/90">Back</button>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { ref } from 'vue';
import { transactionStore } from '../../store/pinia/transactionStore';
import { mapGetters, mapActions } from 'vuex';
import { random } from 'lodash';
import { getErrorCodeMessage } from '@/utils';

export default {
  name: 'WeeklyRaffleModal',
  components: {
    PuntModal,
  },

  setup() {
    const transStore = transactionStore()
    return { transStore }
  },

  data() {
    let nextFriday = new Date();
    nextFriday = new Date(nextFriday.setDate(nextFriday.getDate() + (5 + 7 - nextFriday.getDay()) % 7));
    nextFriday.setHours(24)
    nextFriday.setMinutes(0)
    nextFriday.setSeconds(0)

    const today = new Date()
    let timeUntilFriday = ref(nextFriday - today.getTime());
    return { timeUntilFriday: timeUntilFriday, tickets: [], sweepCoinsSpent: 0, currentTickets: [], previousTickets: [], selectedOutput: "general", random, previousRaffle: {}, currentRaffle: {}, loading: true, clockInterval: null };
  },

  created() {
    this.clockInterval=setInterval(() => {
      if (this.timeUntilFriday > 0) {
        this.timeUntilFriday -= 1000
      }
    }, 1000)
  },

  mounted() {
    const userId = this.me.id;
    if (userId) {
      this.loading = true;
      Promise.all([
        this.fetchWeeklyRaffleProgress(userId),
        this.fetchWeeklyRaffleTickets(userId),
        this.fetchWeeklyRaffleStatus(),
      ]).then((resolve, reject) => this.loading = false);
    }
  },

  unmounted(){
    clearInterval(this.clockInterval);
  },

  methods: {
    timeUntilRaffle(distance) {
      const seconds = Math.floor((distance / 1000) % 60);
      const minutes = Math.floor((distance / 1000 / 60) % 60);
      const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      return ({ 'seconds': seconds, "minutes": minutes, "hours": hours, "days": days })
    },

    changeSelectedOutput(desiredOutput) {
      this.selectedOutput = desiredOutput
    },

    async fetchWeeklyRaffleProgress(userId) {
      try {
        const response = await this.transStore.fetchWeeklyRaffleProgress(userId);
        this.sweepCoinsSpent = response.data.spent_sweeps_coins?response.data.spent_sweeps_coins:0;
      } catch (error) {
        const { message } = getErrorCodeMessage(error, 'Cannot fetch weekly raffle progress data!');
        this.$toast.error('Something went wrong: ' + message);
      }
    },

    async fetchWeeklyRaffleStatus() {
      try {
        const response = await this.transStore.fetchWeeklyRaffleStatus();
        this.currentRaffle = response.data[0];
        this.previousRaffle = response.data[1];
      } catch (error) {
        const { message } = getErrorCodeMessage(error, 'Cannot fetch weekly raffle status data!');
        this.$toast.error('Something went wrong: ' + message);
      }
    },

    async fetchWeeklyRaffleTickets(userId) {
      try {
        const response = await this.transStore.fetchWeeklyRaffleTickets(userId);
        this.currentTickets = response.data['Current Raffle'];
        this.previousTickets = response.data['Previous Raffle'];
      } catch (error) {
        const { message } = getErrorCodeMessage(error, 'Cannot fetch weekly raffle tickets data!');
        this.$toast.error('Something went wrong: ' + message);
      }
    }

  },
  computed: {
    ...mapGetters(['me']),
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  height: 14px;
  @apply bg-dark-blue;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 8px;

  .striped-bar {
    @apply bg-orange;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    height: 100%;
    background-size: 1rem 100%;
  }
}
</style>
