<template>

    <div class="jackpot-counter bg-[rgba(0,0,0,0.50)] p-1 absolute z   z-30 font-bold " >
      <img class="w-6 h-auto  inline" :src="getIcon(currency)" /> <span > {{ maxamount.toLocaleString() }} </span>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
export default {
  name: 'JackpotCounter',
  props: {
    currency: {
      default:'SC'
    },
    amount: 
    {
      default:0
    }
  },
  components: {
    
  },
  mounted()
  {
    this.init();
  },
  data() {
    return {
      maxamount: 0,
      interval : null
    };
  },
  methods: {
      
    getIcon(currency) {
      return getBalanceIcon(currency);
    },

    init()
    {
      var obj = this;
      setTimeout(() => {
        obj.interval = setInterval(function(){
        
        if(parseFloat(obj.maxamount) >= obj.amount)
          clearInterval(obj.interval);
        else
        obj.maxamount = parseFloat(obj.maxamount) + 50;
        }, 1);
      }, 1000);
      
    }
  },
};
</script>
<style>
.jackpot-counter 
{
  top:5px;
  left:10px;
  width:calc(100% - 20px);
  border-radius: 20px;
}
 
.jackpot-counter  *
{
  vertical-align: middle;
}
</style>
 
 