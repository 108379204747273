<template>
  <home-slider
      v-if="category.type === 'banner' && category.data?.images"
      :category="category"
  />

  <home-category-slider
      v-if="category.type === 'category' && category.show_in_home && category.games.length > 0"
      :category="category"
  />
  <bet-wins v-if="category.type === 'section' && category.slug === 'bet-wins'" />
  <QuickBuy v-if="category.type === 'section' && category.slug === 'quick-buy'" />
</template>

<script>

import HomeCategorySlider from "@/components/Home/HomeCategorySlider.vue";
import BetWins from "@/components/Home/BetWins.vue";
import QuickBuy from "@/components/Home/QuickBuy.vue";
import HomeSlider from "@/components/Home/Slider/HomeSlider.vue";

export default {
  name: 'HomeSection',
  components: {QuickBuy, BetWins, HomeCategorySlider, HomeSlider},
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>