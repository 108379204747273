import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

export const challengeStore = defineStore('challenge', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      featured: null,
    };
  },
  getters: {},
  actions: {
    async fetchAll(params,type) {
      return await axios.get('challenges/'+type, { params },);
    },
    async searchGame(params) {
      return await axios.get('gamesuggestions', { params });
    },
    async createChallenge(params) {
      return await axios.post('challenges', params);
    },
  },
});
