<template>
  <landing v-if="!this.isLoggedIn && this.landingpage" />
  <div
    v-else
    class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 items-center child:max-w-[1200px] home-page-wrapper"
    style="background-image: url('/images/bannerslider/homebg.png')">
    
    <div class="grid grid-cols-1 md:p-4 pb-4 w-full max-[480px]:p-0">
      <home-slider v-if="categoryOptionsFetched" :category="mainBanner" :isMainBanner="true" />

      <home-section
          v-for="(cat, i) in getHomeCategories"
          :key="i"
          :category="cat"
      />

      <GameHistoryTable />

      <!-- Conversion Pixel for [lead]- DO NOT MODIFY -->
        <img src="https://arttrk.com/pixel/?ad_log=referer&action=lead&pixid=acb35c35-c9cb-4751-ab1b-358989a1a203" width="1" height="1" border="0">
      <!-- End of Conversion Pixel -->

    </div>
  </div>
</template>

<script>
import './style.css';
import { mapGetters } from 'vuex';
import Landing from '@/components/Home/Landing.vue';
import HomeSection from "@/components/Home/HomeSection.vue";
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import HomeSlider from '@/components/Home/Slider/HomeSlider.vue';
export default {
  name: 'Home',
  components: {
    HomeSlider,
    Landing,
    HomeSection,
    GameHistoryTable
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn', 'landingpage', 'categoryOptions', 'categoryOptionsFetched']),
    getHomeCategories() {
      return this.categoryOptions.filter((category) => {
        return (category.type === 'banner' || category.show_in_home) && category.slug !== 'main-banner';
      })
    },
    mainBanner() {
      return this.categoryOptions.find((category) => {
        return category.type === 'banner' && category.slug === 'main-banner';
      })
    }
  },
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    '$route.query.aid': {
      handler: function (newState) {
        if (this.$route.query.aid) {
          window.localStorage['aid'] = this.$route.query.aid;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
