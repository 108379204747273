<template>
  <verify-account
    data-verifid="{me.verification_id}"
    :reverification="me.prev_verification_id"
    context="claim your hourly bonus"
    v-if="me.verification_id === null" />
  <div v-else>
    <div class="rounded-lg bg-secondary p-2">
      <div class="mt-4">
        <span class="bold text-lg font-bold">Redeem Bonus Drop</span>
        <br />
        <span class="text-sm"
          >Find bonus drop codes on our social media's such as Twitter & Telegram.</span
        >

        <div class="mt-4">
          <label for="bonus-code" class="mb-2 block text-sm font-medium">
            Code
            <span class="text-red-800">*</span>
          </label>
          <input type="text" v-model="code" id="bonus-code" class="input-bet" required />
        </div>
        <button
          v-on:click="claimBonus()"
          :disabled="loading"
          class="btn-bet disabled:opacity-70">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { doRecaptcha } from '../../lib/recaptcha';
import axios from '../../lib/myAxios';
import VerifyAccount from '@/components/Bank/VerifyAccount.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getErrorCodeMessage } from '@/utils';

export default {
  name: 'Offers',
  components: {
    VerifyAccount,
  },
  data() {
    return {
      code: '',
      welcomeCode: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['me']),
  },
  methods: {
    async claimBonus(isWelcome = false) {
      let code = isWelcome ? this.welcomeCode : this.code;
      if (!code || code.length === 0) {
        this.$toast.error('Bonus Code Empty!');
        return;
      }
      this.loading = true;
      try {
        const response = await axios.post('bonus', { code, fp: window.fp });
        this.loading = false;
        const message = response.data.message;
        this.$toast.success(message);
        if (message === 'Promotion redeemed successfully') {
          this.$router.push({ path: '/', query: { tab: 'bank' } });
        }
      } catch (error) {
        this.loading = false;
        const { message } = getErrorCodeMessage(error, 'An error occurred');
        this.$toast.error(message);
      }
    },
  },
  mounted() {
    const code = this.$route.query.code;
    if (code) {
      this.code = code;
    }
  }
};
</script>

<style scoped>
  .btn-bet{
    box-shadow: unset;
  }
  .input-bet:focus{
    --tw-ring-color:#002722;
  }
</style>
